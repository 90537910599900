.property_details-section a {
    transition: transform 1s ease-in-out;
}
.property_details-section a:hover {
    transform: scale(0.98);
}

.investment-section .imgHover_effect img {
    height: 515px;
}
.property_details-section .display-img img {
    height: 600px;
}
.property_details-section .invest-details p,
.property_details-section .invest-details h4 {
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 20px;
}
.property_details-section .invest-description {
    font-family: 'Neue Haas Grotesk Display Pro';
    /* font-size: 20px; */
}

.documents-section .no-availableDocs span {
    outline: 1px solid var(--charcoal-color);
}
.documents-section .no-availableDocs p {
    color: var(--charcoal-color);
}

@media (max-width: 767px) {
    .investment-section .imgHover_effect img {
        height: 393px !important;
        object-fit: cover;
    }
    .property_details-section .imgHover_effect img {
        height: 411px !important;
        object-fit: cover;
    }
}