.home_page-section {
    margin-top: -75px;
    /* max-width: 1310px; */
    /* margin: 0 auto; */
}

/* Hero Section */
/* .home_page-section .hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: var(--dark-color);
  } */
.home_page-section .hero {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-image: url(../../utils/images/hero-bg.jpg);
  height: 100vh;
}

.home_page-section .hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000049;
  z-index: 1;
}

.home_page-section .hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.home_page-section .hero-text {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 30px;
  z-index: 2;
}
.home_page-section .hero-text .hero-text_btn {
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 42px;
  z-index: 2;
  border-radius: 20px;
  border: 2px solid var(--grey-color);
  font-weight: bold;
  font-size: 18px;}
  
.home_page-section .hero-text h1 {
  color: var(--basic-white);
  z-index: 2;
}

.home_page-section .hero-video::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #110f0f80;
  z-index: 2;
}




/* Brand Info Section */

.home_page-section .brand_info-section {
  max-width: 1200px;
  margin: 0 auto;
  color: var(--basic_text-white);
  /* font-size: 22px; */
}
.home_page-section .brand_info-section h4 {
  font-size: 20px;
  max-width: 450px;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 400;
}
.home_page-section .brand_info-section h3 {
  font-family: "Besley", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 39px;
}

.home_page-section .brand_info-section .fa-plus {
  font-size: 16px;
}


/* Testimony section */
.home_page-section .testimony-section {
  background-color: var(--bg_grey-color);
}
.home_page-section .testimony-section .carousel {
  max-width: 950px;
  margin: 0 auto;
}
.testimony-section .testimony-text p {
  font-size: 32px;
  font-family: 'Neue Haas Grotesk Display Pro lightweight';
  line-height: 1.2;
}
.testimony-section .testimony-text h4 {
  font-size: 25px;
  font-weight: bold;
}
.testimony-section .testimony-text h5 {
  /* font-size: 25px; */
}

@media (max-width: 768px) {
  .home_page-section .hero-text {
    padding-bottom: 70px;
  }
  .home_page-section {
    /* margin-top: 0px; */
  }
  .home_page-section .brand_info-section h3 {
    font-size: 30px;
  }  
  .testimony-section .testimony-text p {
    font-size: 25px;
    font-family: 'Neue Haas Grotesk Display Pro';
  }
  .testimony-section .testimony-text h4 {
    font-size: 20px;
    /* font-weight: bold; */
  }
  
}