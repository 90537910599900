footer {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}
footer::before {
    content: '';
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 98.5%;
    border-top: 1px solid var(--charcoal-color);
}

.footer-contact form {
    max-width: 350px;
}

.footer-contact input.form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--basic-white);
    background-color: var(--basic-dark);
    padding: 4px 0px;
    color: var(--grey-color);
}
.footer-contact input::placeholder {
    color: var(--grey-color);
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    font-weight: 500;
}
.footer-contact button {
    background-color: var(--basic-dark);
    border: none;
    border-radius: 50px;
    color: var(--basic-white);
    padding: 1px 15px;
}
.footer-contact button:hover {
    scale: 0.98;
}
.footer-links a:hover {
    color: var(--grey-color);
}
.footer-links ul li {
    margin-bottom: 7px;
}
.footer-links a {
    font-family: 'Neue Haas Grotesk Display Pro';
}


footer .social-links a {
    outline: 1px solid var(--basic-white);    
    border-radius: 100%;
    padding: 4px 10px;
    transition: background-color 0.7s ease-in-out, outline 2.3s ease-in-out;
}
footer .social-links a:hover {
    background-color: var(--charcoal-color);
    outline: none;
}

@media (max-width: 768px) {
    .footer-links a,
    .footer-links h4 {
        font-size: 16px;
    }
    .copyright {
        font-size: 14px;
    }
}