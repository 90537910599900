.about-section .about-heading {
    max-width: 1000px;
}
.about-section .values-info {
    max-width: 450px;
}
.about-section ul {
    list-style: inside;
}

.about-heading h4 {
    font-size: 38px;
    font-weight: normal;
}
.about-hero img {
    height: 650px;
}
.about-section .team-members img {
    height: 350px;
    object-fit: cover;
}


@media (max-width: 768px ) {
    .about-heading h4 {
        font-size: 29px;
    }
    .about-hero img {
        height: 500px !important;
        object-fit: cover;
    }
    .about-section .team-members img {
        height: 306px !important;
        object-fit: cover;
    }
}