@import url('https://fonts.googleapis.com/css2?family=Besley:ital,wght@0,400..900;1,400..900&display=swap');

/* @font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../src/utils/fonts/NeueHaasGrotesk/NeueHaasDisplay-Mediu.woff') format('woff2'),
       url('../src/utils/fonts/NeueHaasGrotesk/NeueHaasDisplay-Mediu.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('../src/utils/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayRoman.ttf') format('woff2'),
       url('../src/utils/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayRoman.ttf') format('woff');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro lightweight';
  src: url('../src/utils/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayLight.ttf') format('woff2'),
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro bold';
  src: url('../src/utils/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayBold.ttf') format('woff2'),
       url('../src/utils/fonts/neue-haas-grotesk-display-pro-cufonfonts/NeueHaasDisplayBold.ttf') format('woff');
}

html {
  scroll-behavior: smooth;
}
:root {
  --dark-color: #000;
  --basic_text-white: #EBEDF0;
  --basic-white: #fff;
  --charcoal-color: #3F3F3F;
  --grey-color: #999999;
  --bg_grey-color: #E6E7E8;
  --form-placholder-color: #7E7D7D;
}

body {
  /* font-family: 'Neue Haas Grotesk Display Pro', sans-serif; */
  background-color: var(--dark-color);
  transition: transform 1s ease;
}

.app {
  max-width: 1440px;
  margin: 0 auto;
}
.page-width {
  color: var(--basic-white);
  max-width: 1200px;
  margin: 0 auto;
}

/* Custom Fonts */
.custom-besleyFont {
  font-family: "Besley", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.custom-neueFont_roman {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-optical-sizing: auto;
}
.custom-neueFont_light {
  font-family: 'Neue Haas Grotesk Display Pro lightweight';
  font-optical-sizing: auto;
}
.custom-neueFont_Bold {
  font-family: 'Neue Haas Grotesk Display Pro bold';
  font-optical-sizing: auto;
}

/* Custom Buttons */
.customBtn {
  background-color: var(--basic-dark);
  outline: 1px solid var(--grey-color);
  border-radius: 50px;
  color: var(--basic-white);
  padding: 7px 20px;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 500;
  font-style: normal;
  transition: background-color 1.3s ease, outline 0.7s ease-in-out;
  animation-timing-function: ease-in;
  animation-duration: 40000ms;
}
.customBtn-circle {
  background-color: var(--basic-dark);
  outline: 1px solid var(--grey-color);
  border-radius: 50%;
  color: var(--basic-white);
  padding: 8px 15px;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 500;
  font-style: normal;
  transition: background-color 0.7s ease-in-out, outline 2.3s ease-in-out;
  animation-timing-function: ease-in-out;
  animation-duration: 40000ms;
}
.customBtn-closed {
  outline-color: transparent;
  background-color: var(--charcoal-color);
}
.customBtn:hover {
  background-color: var(--charcoal-color);
  outline: none;
}
.customBtn-circle:hover {
  background-color: var(--charcoal-color);
  outline: none;
}

/* Utils */
textarea:focus,
input:focus {
  box-shadow: none !important;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #fff;
}
h1 {
  color: var(--basic-white);
  font-family: "Besley", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(3rem, 4vw + 1rem, 4rem);
}

h2 {
  font-family: "Besley", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.imgHover_effect {
  overflow: hidden;
}
.imgHover_effect img {
  transition: transform 1s ease;
}

.imgHover_effect img:hover {
  transform: scale(1.2);
}

/**Styling scrollable elements*/
.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
}

.js-scroll.scrolled {
  opacity: 1;
}

.scrolled.fade-in {
  animation: fade-in 1.5s ease-in-out both;
}

.scrolled.fade-in-bottom {
  animation: fade-in-bottom 1.5s ease-in-out both;
}

.scrolled.slide-left {
  animation: slide-in-left 1.5s ease-in-out both;
}

.scrolled.slide-right {
  animation: slide-in-right 1.5s ease-in-out both;
}



@media (max-width: 600px) {
  img {
    height: auto !important;
  }
  h1 {
    font-size: 2.23rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .hero-text h1 {
    font-size: 61px;
  }
}
@media (min-width: 1025px) {
  .hero-text h1 {
    font-size: 80px; 
  }
}



@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
