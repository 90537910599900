.contact_page-section {
    margin-top: -75px;
}

.contact_page-section .hero {
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat-x;
    position: relative;
    background-image: url(../../utils/images/contactHero_Img.png);
    height: 500px;
    /* width: 100%; */
}

.contact_page-section .hero-text {
    max-width: 1210px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-bottom: 30px;
}
.contact_page-section hr {
    border: 1px solid var(--grey-color);
}
.contact_page-section a:hover {
    color: var(--form-placholder-color);
}

@media (max-width: 768px) {
    .contact_page-section .hero {
        height: 326px;
        background-attachment: scroll;
    }
    .contact_page-section .hero-text {
        position: absolute;
        bottom: 10px;
        /* left: 1%; */
    }
}