
.news-item {
    position: relative;
}
.image-shade {
    position: absolute;
    bottom: 0;
}
.news-item .news-details {
    position: absolute;
    bottom: 10px;
    left: 20px;
}
.news-item img {
    height: 375px;
    object-fit: cover;
}
.imgHover_effect .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}
  
.imgHover_effect .news-details {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 2;
    color: #fff;
}
  
.imgHover_effect:hover img {
    transform: scale(1.2);
}
  
.imgHover_effect:hover .overlay {
    transform: scale(1);
}

.blog_details-section .display-img img {
    max-height: 450px;
    object-fit: cover;
}

.blog-details-text {
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 20px;
}

@media (max-width: 768px ) {
    .blog_details-section .display-img img {
        max-height: 406px !important;
        object-fit: cover !important;
    }
}